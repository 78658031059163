@font-face {
  font-family: "lucide";
  src: url('lucide.eot?t=1730123138153'); /* IE9*/
  src: url('lucide.eot?t=1730123138153#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url("lucide.woff2?t=1730123138153") format("woff2"),
  url("lucide.woff?t=1730123138153") format("woff"),
  url('lucide.ttf?t=1730123138153') format('truetype'), /* chrome, firefox, opera, Safari, Android, iOS 4.2+*/
  url('lucide.svg?t=1730123138153#lucide') format('svg'); /* iOS 4.1- */
}

[class^="icon-"], [class*=" icon-"] {
  font-family: 'lucide' !important;
  font-size:inherit;
  font-style:normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.icon-a-arrow-down:before { content: "\e58a"; }
.icon-a-arrow-up:before { content: "\e58b"; }
.icon-a-large-small:before { content: "\e58c"; }
.icon-accessibility:before { content: "\e297"; }
.icon-activity:before { content: "\e038"; }
.icon-air-vent:before { content: "\e351"; }
.icon-airplay:before { content: "\e039"; }
.icon-alarm-clock-check:before { content: "\e1ec"; }
.icon-alarm-clock-minus:before { content: "\e1ed"; }
.icon-alarm-clock-off:before { content: "\e23b"; }
.icon-alarm-clock-plus:before { content: "\e1ee"; }
.icon-alarm-clock:before { content: "\e03a"; }
.icon-alarm-smoke:before { content: "\e580"; }
.icon-album:before { content: "\e03b"; }
.icon-align-center-horizontal:before { content: "\e26c"; }
.icon-align-center-vertical:before { content: "\e26d"; }
.icon-align-center:before { content: "\e03c"; }
.icon-align-end-horizontal:before { content: "\e26e"; }
.icon-align-end-vertical:before { content: "\e26f"; }
.icon-align-horizontal-distribute-center:before { content: "\e03d"; }
.icon-align-horizontal-distribute-end:before { content: "\e03e"; }
.icon-align-horizontal-distribute-start:before { content: "\e03f"; }
.icon-align-horizontal-justify-center:before { content: "\e272"; }
.icon-align-horizontal-justify-end:before { content: "\e273"; }
.icon-align-horizontal-justify-start:before { content: "\e274"; }
.icon-align-horizontal-space-around:before { content: "\e275"; }
.icon-align-horizontal-space-between:before { content: "\e276"; }
.icon-align-justify:before { content: "\e040"; }
.icon-align-left:before { content: "\e041"; }
.icon-align-right:before { content: "\e042"; }
.icon-align-start-horizontal:before { content: "\e270"; }
.icon-align-start-vertical:before { content: "\e271"; }
.icon-align-vertical-distribute-center:before { content: "\e27e"; }
.icon-align-vertical-distribute-end:before { content: "\e27f"; }
.icon-align-vertical-distribute-start:before { content: "\e280"; }
.icon-align-vertical-justify-center:before { content: "\e277"; }
.icon-align-vertical-justify-end:before { content: "\e278"; }
.icon-align-vertical-justify-start:before { content: "\e279"; }
.icon-align-vertical-space-around:before { content: "\e27a"; }
.icon-align-vertical-space-between:before { content: "\e27b"; }
.icon-ambulance:before { content: "\e5c0"; }
.icon-ampersand:before { content: "\e4a1"; }
.icon-ampersands:before { content: "\e4a2"; }
.icon-amphora:before { content: "\e620"; }
.icon-anchor:before { content: "\e043"; }
.icon-angry:before { content: "\e2fc"; }
.icon-annoyed:before { content: "\e2fd"; }
.icon-antenna:before { content: "\e4e7"; }
.icon-anvil:before { content: "\e585"; }
.icon-aperture:before { content: "\e044"; }
.icon-app-window-mac:before { content: "\e5d7"; }
.icon-app-window:before { content: "\e42b"; }
.icon-apple:before { content: "\e352"; }
.icon-archive-restore:before { content: "\e2cd"; }
.icon-archive-x:before { content: "\e511"; }
.icon-archive:before { content: "\e045"; }
.icon-armchair:before { content: "\e2c0"; }
.icon-arrow-big-down-dash:before { content: "\e422"; }
.icon-arrow-big-down:before { content: "\e1e1"; }
.icon-arrow-big-left-dash:before { content: "\e423"; }
.icon-arrow-big-left:before { content: "\e1e2"; }
.icon-arrow-big-right-dash:before { content: "\e424"; }
.icon-arrow-big-right:before { content: "\e1e3"; }
.icon-arrow-big-up-dash:before { content: "\e425"; }
.icon-arrow-big-up:before { content: "\e1e4"; }
.icon-arrow-down-0-1:before { content: "\e418"; }
.icon-arrow-down-1-0:before { content: "\e419"; }
.icon-arrow-down-a-z:before { content: "\e41a"; }
.icon-arrow-down-from-line:before { content: "\e459"; }
.icon-arrow-down-left:before { content: "\e047"; }
.icon-arrow-down-narrow-wide:before { content: "\e048"; }
.icon-arrow-down-right:before { content: "\e049"; }
.icon-arrow-down-to-dot:before { content: "\e452"; }
.icon-arrow-down-to-line:before { content: "\e45a"; }
.icon-arrow-down-up:before { content: "\e04a"; }
.icon-arrow-down-wide-narrow:before { content: "\e04b"; }
.icon-arrow-down-z-a:before { content: "\e41b"; }
.icon-arrow-down:before { content: "\e046"; }
.icon-arrow-left-from-line:before { content: "\e45b"; }
.icon-arrow-left-right:before { content: "\e24a"; }
.icon-arrow-left-to-line:before { content: "\e45c"; }
.icon-arrow-left:before { content: "\e04c"; }
.icon-arrow-right-from-line:before { content: "\e45d"; }
.icon-arrow-right-left:before { content: "\e41c"; }
.icon-arrow-right-to-line:before { content: "\e45e"; }
.icon-arrow-right:before { content: "\e04d"; }
.icon-arrow-up-0-1:before { content: "\e41d"; }
.icon-arrow-up-1-0:before { content: "\e41e"; }
.icon-arrow-up-a-z:before { content: "\e41f"; }
.icon-arrow-up-down:before { content: "\e381"; }
.icon-arrow-up-from-dot:before { content: "\e453"; }
.icon-arrow-up-from-line:before { content: "\e45f"; }
.icon-arrow-up-left:before { content: "\e04f"; }
.icon-arrow-up-narrow-wide:before { content: "\e050"; }
.icon-arrow-up-right:before { content: "\e051"; }
.icon-arrow-up-to-line:before { content: "\e460"; }
.icon-arrow-up-wide-narrow:before { content: "\e420"; }
.icon-arrow-up-z-a:before { content: "\e421"; }
.icon-arrow-up:before { content: "\e04e"; }
.icon-arrows-up-from-line:before { content: "\e4d9"; }
.icon-asterisk:before { content: "\e1ef"; }
.icon-at-sign:before { content: "\e052"; }
.icon-atom:before { content: "\e3db"; }
.icon-audio-lines:before { content: "\e55f"; }
.icon-audio-waveform:before { content: "\e560"; }
.icon-award:before { content: "\e053"; }
.icon-axe:before { content: "\e054"; }
.icon-axis-3d:before { content: "\e2fe"; }
.icon-baby:before { content: "\e2ce"; }
.icon-backpack:before { content: "\e2c8"; }
.icon-badge-alert:before { content: "\e47a"; }
.icon-badge-cent:before { content: "\e514"; }
.icon-badge-check:before { content: "\e241"; }
.icon-badge-dollar-sign:before { content: "\e47b"; }
.icon-badge-euro:before { content: "\e515"; }
.icon-badge-help:before { content: "\e47c"; }
.icon-badge-indian-rupee:before { content: "\e516"; }
.icon-badge-info:before { content: "\e47d"; }
.icon-badge-japanese-yen:before { content: "\e517"; }
.icon-badge-minus:before { content: "\e47e"; }
.icon-badge-percent:before { content: "\e47f"; }
.icon-badge-plus:before { content: "\e480"; }
.icon-badge-pound-sterling:before { content: "\e518"; }
.icon-badge-russian-ruble:before { content: "\e519"; }
.icon-badge-swiss-franc:before { content: "\e51a"; }
.icon-badge-x:before { content: "\e481"; }
.icon-badge:before { content: "\e479"; }
.icon-baggage-claim:before { content: "\e2c9"; }
.icon-ban:before { content: "\e055"; }
.icon-banana:before { content: "\e353"; }
.icon-bandage:before { content: "\e622"; }
.icon-banknote:before { content: "\e056"; }
.icon-barcode:before { content: "\e538"; }
.icon-baseline:before { content: "\e285"; }
.icon-bath:before { content: "\e2ab"; }
.icon-battery-charging:before { content: "\e058"; }
.icon-battery-full:before { content: "\e059"; }
.icon-battery-low:before { content: "\e05a"; }
.icon-battery-medium:before { content: "\e05b"; }
.icon-battery-warning:before { content: "\e3b0"; }
.icon-battery:before { content: "\e057"; }
.icon-beaker:before { content: "\e05c"; }
.icon-bean-off:before { content: "\e394"; }
.icon-bean:before { content: "\e393"; }
.icon-bed-double:before { content: "\e2c2"; }
.icon-bed-single:before { content: "\e2c3"; }
.icon-bed:before { content: "\e2c1"; }
.icon-beef:before { content: "\e3a9"; }
.icon-beer-off:before { content: "\e5de"; }
.icon-beer:before { content: "\e2cf"; }
.icon-bell-dot:before { content: "\e430"; }
.icon-bell-electric:before { content: "\e581"; }
.icon-bell-minus:before { content: "\e1f0"; }
.icon-bell-off:before { content: "\e05e"; }
.icon-bell-plus:before { content: "\e1f1"; }
.icon-bell-ring:before { content: "\e224"; }
.icon-bell:before { content: "\e05d"; }
.icon-between-horizontal-end:before { content: "\e596"; }
.icon-between-horizontal-start:before { content: "\e597"; }
.icon-between-vertical-end:before { content: "\e598"; }
.icon-between-vertical-start:before { content: "\e599"; }
.icon-biceps-flexed:before { content: "\e5f0"; }
.icon-bike:before { content: "\e1d2"; }
.icon-binary:before { content: "\e1f2"; }
.icon-binoculars:before { content: "\e626"; }
.icon-biohazard:before { content: "\e446"; }
.icon-bird:before { content: "\e3c9"; }
.icon-bitcoin:before { content: "\e05f"; }
.icon-blend:before { content: "\e5a1"; }
.icon-blinds:before { content: "\e3c4"; }
.icon-blocks:before { content: "\e4ff"; }
.icon-bluetooth-connected:before { content: "\e1b8"; }
.icon-bluetooth-off:before { content: "\e1b9"; }
.icon-bluetooth-searching:before { content: "\e1ba"; }
.icon-bluetooth:before { content: "\e060"; }
.icon-bold:before { content: "\e061"; }
.icon-bolt:before { content: "\e591"; }
.icon-bomb:before { content: "\e2ff"; }
.icon-bone:before { content: "\e35c"; }
.icon-book-a:before { content: "\e549"; }
.icon-book-audio:before { content: "\e54a"; }
.icon-book-check:before { content: "\e54b"; }
.icon-book-copy:before { content: "\e3f1"; }
.icon-book-dashed:before { content: "\e3f2"; }
.icon-book-down:before { content: "\e3f3"; }
.icon-book-headphones:before { content: "\e54c"; }
.icon-book-heart:before { content: "\e54d"; }
.icon-book-image:before { content: "\e54e"; }
.icon-book-key:before { content: "\e3f4"; }
.icon-book-lock:before { content: "\e3f5"; }
.icon-book-marked:before { content: "\e3f6"; }
.icon-book-minus:before { content: "\e3f7"; }
.icon-book-open-check:before { content: "\e385"; }
.icon-book-open-text:before { content: "\e54f"; }
.icon-book-open:before { content: "\e063"; }
.icon-book-plus:before { content: "\e3f8"; }
.icon-book-text:before { content: "\e550"; }
.icon-book-type:before { content: "\e551"; }
.icon-book-up-2:before { content: "\e4ab"; }
.icon-book-up:before { content: "\e3f9"; }
.icon-book-user:before { content: "\e552"; }
.icon-book-x:before { content: "\e3fa"; }
.icon-book:before { content: "\e062"; }
.icon-bookmark-check:before { content: "\e524"; }
.icon-bookmark-minus:before { content: "\e23c"; }
.icon-bookmark-plus:before { content: "\e23d"; }
.icon-bookmark-x:before { content: "\e525"; }
.icon-bookmark:before { content: "\e064"; }
.icon-boom-box:before { content: "\e4f3"; }
.icon-bot-message-square:before { content: "\e5d3"; }
.icon-bot-off:before { content: "\e5e5"; }
.icon-bot:before { content: "\e1bb"; }
.icon-box:before { content: "\e065"; }
.icon-boxes:before { content: "\e2d0"; }
.icon-braces:before { content: "\e36e"; }
.icon-brackets:before { content: "\e448"; }
.icon-brain-circuit:before { content: "\e3cb"; }
.icon-brain-cog:before { content: "\e3cc"; }
.icon-brain:before { content: "\e3ca"; }
.icon-brick-wall:before { content: "\e586"; }
.icon-briefcase-business:before { content: "\e5da"; }
.icon-briefcase-conveyor-belt:before { content: "\e630"; }
.icon-briefcase-medical:before { content: "\e5db"; }
.icon-briefcase:before { content: "\e066"; }
.icon-bring-to-front:before { content: "\e4f4"; }
.icon-brush:before { content: "\e1d3"; }
.icon-bug-off:before { content: "\e512"; }
.icon-bug-play:before { content: "\e513"; }
.icon-bug:before { content: "\e20c"; }
.icon-building-2:before { content: "\e290"; }
.icon-building:before { content: "\e1cc"; }
.icon-bus-front:before { content: "\e500"; }
.icon-bus:before { content: "\e1d4"; }
.icon-cable-car:before { content: "\e501"; }
.icon-cable:before { content: "\e4e8"; }
.icon-cake-slice:before { content: "\e4be"; }
.icon-cake:before { content: "\e348"; }
.icon-calculator:before { content: "\e1bc"; }
.icon-calendar-1:before { content: "\e635"; }
.icon-calendar-arrow-down:before { content: "\e603"; }
.icon-calendar-arrow-up:before { content: "\e604"; }
.icon-calendar-check-2:before { content: "\e2b8"; }
.icon-calendar-check:before { content: "\e2b7"; }
.icon-calendar-clock:before { content: "\e304"; }
.icon-calendar-cog:before { content: "\e5f2"; }
.icon-calendar-days:before { content: "\e2b9"; }
.icon-calendar-fold:before { content: "\e5b9"; }
.icon-calendar-heart:before { content: "\e305"; }
.icon-calendar-minus-2:before { content: "\e5ba"; }
.icon-calendar-minus:before { content: "\e2ba"; }
.icon-calendar-off:before { content: "\e2bb"; }
.icon-calendar-plus-2:before { content: "\e5bb"; }
.icon-calendar-plus:before { content: "\e2bc"; }
.icon-calendar-range:before { content: "\e2bd"; }
.icon-calendar-search:before { content: "\e306"; }
.icon-calendar-x-2:before { content: "\e2bf"; }
.icon-calendar-x:before { content: "\e2be"; }
.icon-calendar:before { content: "\e067"; }
.icon-camera-off:before { content: "\e069"; }
.icon-camera:before { content: "\e068"; }
.icon-candy-cane:before { content: "\e4bf"; }
.icon-candy-off:before { content: "\e396"; }
.icon-candy:before { content: "\e395"; }
.icon-cannabis:before { content: "\e5d9"; }
.icon-captions-off:before { content: "\e5c6"; }
.icon-captions:before { content: "\e3a8"; }
.icon-car-front:before { content: "\e502"; }
.icon-car-taxi-front:before { content: "\e503"; }
.icon-car:before { content: "\e1d5"; }
.icon-caravan:before { content: "\e53e"; }
.icon-carrot:before { content: "\e25a"; }
.icon-case-lower:before { content: "\e3dc"; }
.icon-case-sensitive:before { content: "\e3dd"; }
.icon-case-upper:before { content: "\e3de"; }
.icon-cassette-tape:before { content: "\e4cf"; }
.icon-cast:before { content: "\e06a"; }
.icon-castle:before { content: "\e3e4"; }
.icon-cat:before { content: "\e390"; }
.icon-cctv:before { content: "\e582"; }
.icon-chart-area:before { content: "\e4d8"; }
.icon-chart-bar-big:before { content: "\e4ac"; }
.icon-chart-bar-decreasing:before { content: "\e60c"; }
.icon-chart-bar-increasing:before { content: "\e60d"; }
.icon-chart-bar-stacked:before { content: "\e60e"; }
.icon-chart-bar:before { content: "\e2a2"; }
.icon-chart-candlestick:before { content: "\e4ad"; }
.icon-chart-column-big:before { content: "\e4ae"; }
.icon-chart-column-decreasing:before { content: "\e06b"; }
.icon-chart-column-increasing:before { content: "\e2a4"; }
.icon-chart-column-stacked:before { content: "\e60f"; }
.icon-chart-column:before { content: "\e2a3"; }
.icon-chart-gantt:before { content: "\e629"; }
.icon-chart-line:before { content: "\e2a5"; }
.icon-chart-network:before { content: "\e610"; }
.icon-chart-no-axes-column-decreasing:before { content: "\e06d"; }
.icon-chart-no-axes-column-increasing:before { content: "\e06e"; }
.icon-chart-no-axes-column:before { content: "\e06c"; }
.icon-chart-no-axes-combined:before { content: "\e611"; }
.icon-chart-no-axes-gantt:before { content: "\e4c9"; }
.icon-chart-pie:before { content: "\e06f"; }
.icon-chart-scatter:before { content: "\e48f"; }
.icon-chart-spline:before { content: "\e612"; }
.icon-check-check:before { content: "\e392"; }
.icon-check:before { content: "\e070"; }
.icon-chef-hat:before { content: "\e2ac"; }
.icon-cherry:before { content: "\e354"; }
.icon-chevron-down:before { content: "\e071"; }
.icon-chevron-first:before { content: "\e243"; }
.icon-chevron-last:before { content: "\e244"; }
.icon-chevron-left:before { content: "\e072"; }
.icon-chevron-right:before { content: "\e073"; }
.icon-chevron-up:before { content: "\e074"; }
.icon-chevrons-down-up:before { content: "\e228"; }
.icon-chevrons-down:before { content: "\e075"; }
.icon-chevrons-left-right-ellipsis:before { content: "\e624"; }
.icon-chevrons-left-right:before { content: "\e293"; }
.icon-chevrons-left:before { content: "\e076"; }
.icon-chevrons-right-left:before { content: "\e294"; }
.icon-chevrons-right:before { content: "\e077"; }
.icon-chevrons-up-down:before { content: "\e211"; }
.icon-chevrons-up:before { content: "\e078"; }
.icon-chrome:before { content: "\e079"; }
.icon-church:before { content: "\e3e5"; }
.icon-cigarette-off:before { content: "\e2c7"; }
.icon-cigarette:before { content: "\e2c6"; }
.icon-circle-alert:before { content: "\e07b"; }
.icon-circle-arrow-down:before { content: "\e07c"; }
.icon-circle-arrow-left:before { content: "\e07d"; }
.icon-circle-arrow-out-down-left:before { content: "\e3fc"; }
.icon-circle-arrow-out-down-right:before { content: "\e3fd"; }
.icon-circle-arrow-out-up-left:before { content: "\e3fe"; }
.icon-circle-arrow-out-up-right:before { content: "\e3ff"; }
.icon-circle-arrow-right:before { content: "\e07e"; }
.icon-circle-arrow-up:before { content: "\e07f"; }
.icon-circle-check-big:before { content: "\e080"; }
.icon-circle-check:before { content: "\e226"; }
.icon-circle-chevron-down:before { content: "\e4e2"; }
.icon-circle-chevron-left:before { content: "\e4e3"; }
.icon-circle-chevron-right:before { content: "\e4e4"; }
.icon-circle-chevron-up:before { content: "\e4e5"; }
.icon-circle-dashed:before { content: "\e4b5"; }
.icon-circle-divide:before { content: "\e081"; }
.icon-circle-dollar-sign:before { content: "\e482"; }
.icon-circle-dot-dashed:before { content: "\e4b6"; }
.icon-circle-dot:before { content: "\e349"; }
.icon-circle-ellipsis:before { content: "\e34a"; }
.icon-circle-equal:before { content: "\e405"; }
.icon-circle-fading-arrow-up:before { content: "\e61d"; }
.icon-circle-fading-plus:before { content: "\e5c1"; }
.icon-circle-gauge:before { content: "\e4e6"; }
.icon-circle-help:before { content: "\e082"; }
.icon-circle-minus:before { content: "\e083"; }
.icon-circle-off:before { content: "\e406"; }
.icon-circle-parking-off:before { content: "\e3ce"; }
.icon-circle-parking:before { content: "\e3cd"; }
.icon-circle-pause:before { content: "\e084"; }
.icon-circle-percent:before { content: "\e51f"; }
.icon-circle-play:before { content: "\e085"; }
.icon-circle-plus:before { content: "\e086"; }
.icon-circle-power:before { content: "\e555"; }
.icon-circle-slash-2:before { content: "\e213"; }
.icon-circle-slash:before { content: "\e407"; }
.icon-circle-stop:before { content: "\e087"; }
.icon-circle-user-round:before { content: "\e467"; }
.icon-circle-user:before { content: "\e466"; }
.icon-circle-x:before { content: "\e088"; }
.icon-circle:before { content: "\e07a"; }
.icon-circuit-board:before { content: "\e408"; }
.icon-citrus:before { content: "\e379"; }
.icon-clapperboard:before { content: "\e29b"; }
.icon-clipboard-check:before { content: "\e219"; }
.icon-clipboard-copy:before { content: "\e225"; }
.icon-clipboard-list:before { content: "\e08a"; }
.icon-clipboard-minus:before { content: "\e5c3"; }
.icon-clipboard-paste:before { content: "\e3ec"; }
.icon-clipboard-pen-line:before { content: "\e308"; }
.icon-clipboard-pen:before { content: "\e307"; }
.icon-clipboard-plus:before { content: "\e5c4"; }
.icon-clipboard-type:before { content: "\e309"; }
.icon-clipboard-x:before { content: "\e222"; }
.icon-clipboard:before { content: "\e089"; }
.icon-clock-1:before { content: "\e24b"; }
.icon-clock-10:before { content: "\e24c"; }
.icon-clock-11:before { content: "\e24d"; }
.icon-clock-12:before { content: "\e24e"; }
.icon-clock-2:before { content: "\e24f"; }
.icon-clock-3:before { content: "\e250"; }
.icon-clock-4:before { content: "\e251"; }
.icon-clock-5:before { content: "\e252"; }
.icon-clock-6:before { content: "\e253"; }
.icon-clock-7:before { content: "\e254"; }
.icon-clock-8:before { content: "\e255"; }
.icon-clock-9:before { content: "\e256"; }
.icon-clock-alert:before { content: "\e62f"; }
.icon-clock-arrow-down:before { content: "\e605"; }
.icon-clock-arrow-up:before { content: "\e606"; }
.icon-clock:before { content: "\e08b"; }
.icon-cloud-cog:before { content: "\e30a"; }
.icon-cloud-download:before { content: "\e08d"; }
.icon-cloud-drizzle:before { content: "\e08e"; }
.icon-cloud-fog:before { content: "\e214"; }
.icon-cloud-hail:before { content: "\e08f"; }
.icon-cloud-lightning:before { content: "\e090"; }
.icon-cloud-moon-rain:before { content: "\e2fa"; }
.icon-cloud-moon:before { content: "\e215"; }
.icon-cloud-off:before { content: "\e091"; }
.icon-cloud-rain-wind:before { content: "\e093"; }
.icon-cloud-rain:before { content: "\e092"; }
.icon-cloud-snow:before { content: "\e094"; }
.icon-cloud-sun-rain:before { content: "\e2fb"; }
.icon-cloud-sun:before { content: "\e216"; }
.icon-cloud-upload:before { content: "\e095"; }
.icon-cloud:before { content: "\e08c"; }
.icon-cloudy:before { content: "\e217"; }
.icon-clover:before { content: "\e096"; }
.icon-club:before { content: "\e49b"; }
.icon-code-xml:before { content: "\e206"; }
.icon-code:before { content: "\e097"; }
.icon-codepen:before { content: "\e098"; }
.icon-codesandbox:before { content: "\e099"; }
.icon-coffee:before { content: "\e09a"; }
.icon-cog:before { content: "\e30b"; }
.icon-coins:before { content: "\e09b"; }
.icon-columns-2:before { content: "\e09c"; }
.icon-columns-3:before { content: "\e09d"; }
.icon-columns-4:before { content: "\e58e"; }
.icon-combine:before { content: "\e451"; }
.icon-command:before { content: "\e09e"; }
.icon-compass:before { content: "\e09f"; }
.icon-component:before { content: "\e2ad"; }
.icon-computer:before { content: "\e4e9"; }
.icon-concierge-bell:before { content: "\e37c"; }
.icon-cone:before { content: "\e528"; }
.icon-construction:before { content: "\e3b8"; }
.icon-contact-round:before { content: "\e468"; }
.icon-contact:before { content: "\e0a0"; }
.icon-container:before { content: "\e4da"; }
.icon-contrast:before { content: "\e0a1"; }
.icon-cookie:before { content: "\e26b"; }
.icon-cooking-pot:before { content: "\e589"; }
.icon-copy-check:before { content: "\e400"; }
.icon-copy-minus:before { content: "\e401"; }
.icon-copy-plus:before { content: "\e402"; }
.icon-copy-slash:before { content: "\e403"; }
.icon-copy-x:before { content: "\e404"; }
.icon-copy:before { content: "\e0a2"; }
.icon-copyleft:before { content: "\e0a3"; }
.icon-copyright:before { content: "\e0a4"; }
.icon-corner-down-left:before { content: "\e0a5"; }
.icon-corner-down-right:before { content: "\e0a6"; }
.icon-corner-left-down:before { content: "\e0a7"; }
.icon-corner-left-up:before { content: "\e0a8"; }
.icon-corner-right-down:before { content: "\e0a9"; }
.icon-corner-right-up:before { content: "\e0aa"; }
.icon-corner-up-left:before { content: "\e0ab"; }
.icon-corner-up-right:before { content: "\e0ac"; }
.icon-cpu:before { content: "\e0ad"; }
.icon-creative-commons:before { content: "\e3b6"; }
.icon-credit-card:before { content: "\e0ae"; }
.icon-croissant:before { content: "\e2ae"; }
.icon-crop:before { content: "\e0af"; }
.icon-cross:before { content: "\e1e5"; }
.icon-crosshair:before { content: "\e0b0"; }
.icon-crown:before { content: "\e1d6"; }
.icon-cuboid:before { content: "\e529"; }
.icon-cup-soda:before { content: "\e2d1"; }
.icon-currency:before { content: "\e230"; }
.icon-cylinder:before { content: "\e52a"; }
.icon-dam:before { content: "\e60b"; }
.icon-database-backup:before { content: "\e3af"; }
.icon-database-zap:before { content: "\e510"; }
.icon-database:before { content: "\e0b1"; }
.icon-delete:before { content: "\e0b2"; }
.icon-dessert:before { content: "\e4c0"; }
.icon-diameter:before { content: "\e52b"; }
.icon-diamond-minus:before { content: "\e5e6"; }
.icon-diamond-percent:before { content: "\e520"; }
.icon-diamond-plus:before { content: "\e5e7"; }
.icon-diamond:before { content: "\e2d2"; }
.icon-dice-1:before { content: "\e287"; }
.icon-dice-2:before { content: "\e288"; }
.icon-dice-3:before { content: "\e289"; }
.icon-dice-4:before { content: "\e28a"; }
.icon-dice-5:before { content: "\e28b"; }
.icon-dice-6:before { content: "\e28c"; }
.icon-dices:before { content: "\e2c5"; }
.icon-diff:before { content: "\e30c"; }
.icon-disc-2:before { content: "\e3fb"; }
.icon-disc-3:before { content: "\e499"; }
.icon-disc-album:before { content: "\e561"; }
.icon-disc:before { content: "\e0b3"; }
.icon-divide:before { content: "\e0b4"; }
.icon-dna-off:before { content: "\e398"; }
.icon-dna:before { content: "\e397"; }
.icon-dock:before { content: "\e5d8"; }
.icon-dog:before { content: "\e391"; }
.icon-dollar-sign:before { content: "\e0b5"; }
.icon-donut:before { content: "\e4c1"; }
.icon-door-closed:before { content: "\e3d9"; }
.icon-door-open:before { content: "\e3da"; }
.icon-dot:before { content: "\e454"; }
.icon-download:before { content: "\e0b6"; }
.icon-drafting-compass:before { content: "\e52c"; }
.icon-drama:before { content: "\e526"; }
.icon-dribbble:before { content: "\e0b7"; }
.icon-drill:before { content: "\e592"; }
.icon-droplet:before { content: "\e0b8"; }
.icon-droplets:before { content: "\e0b9"; }
.icon-drum:before { content: "\e562"; }
.icon-drumstick:before { content: "\e25b"; }
.icon-dumbbell:before { content: "\e3a5"; }
.icon-ear-off:before { content: "\e387"; }
.icon-ear:before { content: "\e386"; }
.icon-earth-lock:before { content: "\e5d1"; }
.icon-earth:before { content: "\e1f3"; }
.icon-eclipse:before { content: "\e5a2"; }
.icon-egg-fried:before { content: "\e355"; }
.icon-egg-off:before { content: "\e399"; }
.icon-egg:before { content: "\e25d"; }
.icon-ellipsis-vertical:before { content: "\e0bb"; }
.icon-ellipsis:before { content: "\e0ba"; }
.icon-equal-not:before { content: "\e1be"; }
.icon-equal:before { content: "\e1bd"; }
.icon-eraser:before { content: "\e28f"; }
.icon-ethernet-port:before { content: "\e625"; }
.icon-euro:before { content: "\e0bc"; }
.icon-expand:before { content: "\e21a"; }
.icon-external-link:before { content: "\e0bd"; }
.icon-eye-closed:before { content: "\e633"; }
.icon-eye-off:before { content: "\e0bf"; }
.icon-eye:before { content: "\e0be"; }
.icon-facebook:before { content: "\e0c0"; }
.icon-factory:before { content: "\e29f"; }
.icon-fan:before { content: "\e37d"; }
.icon-fast-forward:before { content: "\e0c1"; }
.icon-feather:before { content: "\e0c2"; }
.icon-fence:before { content: "\e587"; }
.icon-ferris-wheel:before { content: "\e484"; }
.icon-figma:before { content: "\e0c3"; }
.icon-file-archive:before { content: "\e30d"; }
.icon-file-audio-2:before { content: "\e30f"; }
.icon-file-audio:before { content: "\e30e"; }
.icon-file-axis-3d:before { content: "\e310"; }
.icon-file-badge-2:before { content: "\e312"; }
.icon-file-badge:before { content: "\e311"; }
.icon-file-box:before { content: "\e313"; }
.icon-file-chart-column-increasing:before { content: "\e315"; }
.icon-file-chart-column:before { content: "\e314"; }
.icon-file-chart-line:before { content: "\e316"; }
.icon-file-chart-pie:before { content: "\e317"; }
.icon-file-check-2:before { content: "\e0c6"; }
.icon-file-check:before { content: "\e0c5"; }
.icon-file-clock:before { content: "\e318"; }
.icon-file-code-2:before { content: "\e463"; }
.icon-file-code:before { content: "\e0c7"; }
.icon-file-cog:before { content: "\e319"; }
.icon-file-diff:before { content: "\e31a"; }
.icon-file-digit:before { content: "\e0c8"; }
.icon-file-down:before { content: "\e31b"; }
.icon-file-heart:before { content: "\e31c"; }
.icon-file-image:before { content: "\e31d"; }
.icon-file-input:before { content: "\e0c9"; }
.icon-file-json-2:before { content: "\e370"; }
.icon-file-json:before { content: "\e36f"; }
.icon-file-key-2:before { content: "\e31f"; }
.icon-file-key:before { content: "\e31e"; }
.icon-file-lock-2:before { content: "\e321"; }
.icon-file-lock:before { content: "\e320"; }
.icon-file-minus-2:before { content: "\e0cb"; }
.icon-file-minus:before { content: "\e0ca"; }
.icon-file-music:before { content: "\e563"; }
.icon-file-output:before { content: "\e0cc"; }
.icon-file-pen-line:before { content: "\e323"; }
.icon-file-pen:before { content: "\e322"; }
.icon-file-plus-2:before { content: "\e0ce"; }
.icon-file-plus:before { content: "\e0cd"; }
.icon-file-question:before { content: "\e324"; }
.icon-file-scan:before { content: "\e325"; }
.icon-file-search-2:before { content: "\e326"; }
.icon-file-search:before { content: "\e0cf"; }
.icon-file-sliders:before { content: "\e5a5"; }
.icon-file-spreadsheet:before { content: "\e327"; }
.icon-file-stack:before { content: "\e4a6"; }
.icon-file-symlink:before { content: "\e328"; }
.icon-file-terminal:before { content: "\e329"; }
.icon-file-text:before { content: "\e0d0"; }
.icon-file-type-2:before { content: "\e371"; }
.icon-file-type:before { content: "\e32a"; }
.icon-file-up:before { content: "\e32b"; }
.icon-file-user:before { content: "\e632"; }
.icon-file-video-2:before { content: "\e32d"; }
.icon-file-video:before { content: "\e32c"; }
.icon-file-volume-2:before { content: "\e32f"; }
.icon-file-volume:before { content: "\e32e"; }
.icon-file-warning:before { content: "\e330"; }
.icon-file-x-2:before { content: "\e0d2"; }
.icon-file-x:before { content: "\e0d1"; }
.icon-file:before { content: "\e0c4"; }
.icon-files:before { content: "\e0d3"; }
.icon-film:before { content: "\e0d4"; }
.icon-filter-x:before { content: "\e3b9"; }
.icon-filter:before { content: "\e0d5"; }
.icon-fingerprint:before { content: "\e2cb"; }
.icon-fire-extinguisher:before { content: "\e583"; }
.icon-fish-off:before { content: "\e3b4"; }
.icon-fish-symbol:before { content: "\e4f9"; }
.icon-fish:before { content: "\e3aa"; }
.icon-flag-off:before { content: "\e292"; }
.icon-flag-triangle-left:before { content: "\e237"; }
.icon-flag-triangle-right:before { content: "\e238"; }
.icon-flag:before { content: "\e0d6"; }
.icon-flame-kindling:before { content: "\e53f"; }
.icon-flame:before { content: "\e0d7"; }
.icon-flashlight-off:before { content: "\e0d9"; }
.icon-flashlight:before { content: "\e0d8"; }
.icon-flask-conical-off:before { content: "\e39a"; }
.icon-flask-conical:before { content: "\e0da"; }
.icon-flask-round:before { content: "\e0db"; }
.icon-flip-horizontal-2:before { content: "\e362"; }
.icon-flip-horizontal:before { content: "\e361"; }
.icon-flip-vertical-2:before { content: "\e364"; }
.icon-flip-vertical:before { content: "\e363"; }
.icon-flower-2:before { content: "\e2d4"; }
.icon-flower:before { content: "\e2d3"; }
.icon-focus:before { content: "\e29e"; }
.icon-fold-horizontal:before { content: "\e440"; }
.icon-fold-vertical:before { content: "\e441"; }
.icon-folder-archive:before { content: "\e331"; }
.icon-folder-check:before { content: "\e332"; }
.icon-folder-clock:before { content: "\e333"; }
.icon-folder-closed:before { content: "\e334"; }
.icon-folder-code:before { content: "\e600"; }
.icon-folder-cog:before { content: "\e335"; }
.icon-folder-dot:before { content: "\e4ca"; }
.icon-folder-down:before { content: "\e336"; }
.icon-folder-git-2:before { content: "\e40f"; }
.icon-folder-git:before { content: "\e40e"; }
.icon-folder-heart:before { content: "\e337"; }
.icon-folder-input:before { content: "\e338"; }
.icon-folder-kanban:before { content: "\e4cb"; }
.icon-folder-key:before { content: "\e339"; }
.icon-folder-lock:before { content: "\e33a"; }
.icon-folder-minus:before { content: "\e0dd"; }
.icon-folder-open-dot:before { content: "\e4cc"; }
.icon-folder-open:before { content: "\e247"; }
.icon-folder-output:before { content: "\e33b"; }
.icon-folder-pen:before { content: "\e33c"; }
.icon-folder-plus:before { content: "\e0de"; }
.icon-folder-root:before { content: "\e4cd"; }
.icon-folder-search-2:before { content: "\e33e"; }
.icon-folder-search:before { content: "\e33d"; }
.icon-folder-symlink:before { content: "\e33f"; }
.icon-folder-sync:before { content: "\e4ce"; }
.icon-folder-tree:before { content: "\e340"; }
.icon-folder-up:before { content: "\e341"; }
.icon-folder-x:before { content: "\e342"; }
.icon-folder:before { content: "\e0dc"; }
.icon-folders:before { content: "\e343"; }
.icon-footprints:before { content: "\e3bd"; }
.icon-forklift:before { content: "\e3c5"; }
.icon-forward:before { content: "\e229"; }
.icon-frame:before { content: "\e291"; }
.icon-framer:before { content: "\e0df"; }
.icon-frown:before { content: "\e0e0"; }
.icon-fuel:before { content: "\e2af"; }
.icon-fullscreen:before { content: "\e539"; }
.icon-gallery-horizontal-end:before { content: "\e4d4"; }
.icon-gallery-horizontal:before { content: "\e4d3"; }
.icon-gallery-thumbnails:before { content: "\e4d5"; }
.icon-gallery-vertical-end:before { content: "\e4d7"; }
.icon-gallery-vertical:before { content: "\e4d6"; }
.icon-gamepad-2:before { content: "\e0e2"; }
.icon-gamepad:before { content: "\e0e1"; }
.icon-gauge:before { content: "\e1bf"; }
.icon-gavel:before { content: "\e0e3"; }
.icon-gem:before { content: "\e242"; }
.icon-ghost:before { content: "\e20e"; }
.icon-gift:before { content: "\e0e4"; }
.icon-git-branch-plus:before { content: "\e1f4"; }
.icon-git-branch:before { content: "\e0e5"; }
.icon-git-commit-horizontal:before { content: "\e0e6"; }
.icon-git-commit-vertical:before { content: "\e557"; }
.icon-git-compare-arrows:before { content: "\e558"; }
.icon-git-compare:before { content: "\e35d"; }
.icon-git-fork:before { content: "\e28d"; }
.icon-git-graph:before { content: "\e559"; }
.icon-git-merge:before { content: "\e0e7"; }
.icon-git-pull-request-arrow:before { content: "\e55a"; }
.icon-git-pull-request-closed:before { content: "\e35e"; }
.icon-git-pull-request-create-arrow:before { content: "\e55c"; }
.icon-git-pull-request-create:before { content: "\e55b"; }
.icon-git-pull-request-draft:before { content: "\e35f"; }
.icon-git-pull-request:before { content: "\e0e8"; }
.icon-github:before { content: "\e0e9"; }
.icon-gitlab:before { content: "\e0ea"; }
.icon-glass-water:before { content: "\e2d5"; }
.icon-glasses:before { content: "\e20d"; }
.icon-globe-lock:before { content: "\e5d2"; }
.icon-globe:before { content: "\e0eb"; }
.icon-goal:before { content: "\e4aa"; }
.icon-grab:before { content: "\e1e6"; }
.icon-graduation-cap:before { content: "\e234"; }
.icon-grape:before { content: "\e356"; }
.icon-grid-2x2-check:before { content: "\e5e9"; }
.icon-grid-2x2-plus:before { content: "\e62d"; }
.icon-grid-2x2-x:before { content: "\e5ea"; }
.icon-grid-2x2:before { content: "\e504"; }
.icon-grid-3x3:before { content: "\e0ec"; }
.icon-grip-horizontal:before { content: "\e0ed"; }
.icon-grip-vertical:before { content: "\e0ee"; }
.icon-grip:before { content: "\e3b5"; }
.icon-group:before { content: "\e469"; }
.icon-guitar:before { content: "\e564"; }
.icon-ham:before { content: "\e5dc"; }
.icon-hammer:before { content: "\e0ef"; }
.icon-hand-coins:before { content: "\e5bd"; }
.icon-hand-heart:before { content: "\e5be"; }
.icon-hand-helping:before { content: "\e3bc"; }
.icon-hand-metal:before { content: "\e22c"; }
.icon-hand-platter:before { content: "\e5bf"; }
.icon-hand:before { content: "\e1d7"; }
.icon-handshake:before { content: "\e5c5"; }
.icon-hard-drive-download:before { content: "\e4ea"; }
.icon-hard-drive-upload:before { content: "\e4eb"; }
.icon-hard-drive:before { content: "\e0f0"; }
.icon-hard-hat:before { content: "\e0f1"; }
.icon-hash:before { content: "\e0f2"; }
.icon-haze:before { content: "\e0f3"; }
.icon-hdmi-port:before { content: "\e4ec"; }
.icon-heading-1:before { content: "\e389"; }
.icon-heading-2:before { content: "\e38a"; }
.icon-heading-3:before { content: "\e38b"; }
.icon-heading-4:before { content: "\e38c"; }
.icon-heading-5:before { content: "\e38d"; }
.icon-heading-6:before { content: "\e38e"; }
.icon-heading:before { content: "\e388"; }
.icon-headphone-off:before { content: "\e62e"; }
.icon-headphones:before { content: "\e0f4"; }
.icon-headset:before { content: "\e5c2"; }
.icon-heart-crack:before { content: "\e2d6"; }
.icon-heart-handshake:before { content: "\e2d7"; }
.icon-heart-off:before { content: "\e295"; }
.icon-heart-pulse:before { content: "\e372"; }
.icon-heart:before { content: "\e0f5"; }
.icon-heater:before { content: "\e593"; }
.icon-hexagon:before { content: "\e0f6"; }
.icon-highlighter:before { content: "\e0f7"; }
.icon-history:before { content: "\e1f5"; }
.icon-hop-off:before { content: "\e39c"; }
.icon-hop:before { content: "\e39b"; }
.icon-hospital:before { content: "\e5dd"; }
.icon-hotel:before { content: "\e3e6"; }
.icon-hourglass:before { content: "\e296"; }
.icon-house-plug:before { content: "\e5f5"; }
.icon-house-plus:before { content: "\e5f6"; }
.icon-house:before { content: "\e0f8"; }
.icon-ice-cream-bowl:before { content: "\e3ab"; }
.icon-ice-cream-cone:before { content: "\e357"; }
.icon-id-card:before { content: "\e61c"; }
.icon-image-down:before { content: "\e541"; }
.icon-image-minus:before { content: "\e1f6"; }
.icon-image-off:before { content: "\e1c0"; }
.icon-image-play:before { content: "\e5e4"; }
.icon-image-plus:before { content: "\e1f7"; }
.icon-image-up:before { content: "\e5d0"; }
.icon-image:before { content: "\e0f9"; }
.icon-images:before { content: "\e5c9"; }
.icon-import:before { content: "\e22f"; }
.icon-inbox:before { content: "\e0fa"; }
.icon-indent-decrease:before { content: "\e0fb"; }
.icon-indent-increase:before { content: "\e0fc"; }
.icon-indian-rupee:before { content: "\e0fd"; }
.icon-infinity:before { content: "\e1e7"; }
.icon-info:before { content: "\e0fe"; }
.icon-inspection-panel:before { content: "\e588"; }
.icon-instagram:before { content: "\e0ff"; }
.icon-italic:before { content: "\e100"; }
.icon-iteration-ccw:before { content: "\e428"; }
.icon-iteration-cw:before { content: "\e429"; }
.icon-japanese-yen:before { content: "\e101"; }
.icon-joystick:before { content: "\e359"; }
.icon-kanban:before { content: "\e4e1"; }
.icon-key-round:before { content: "\e4a8"; }
.icon-key-square:before { content: "\e4a9"; }
.icon-key:before { content: "\e102"; }
.icon-keyboard-music:before { content: "\e565"; }
.icon-keyboard-off:before { content: "\e5e3"; }
.icon-keyboard:before { content: "\e284"; }
.icon-lamp-ceiling:before { content: "\e2d9"; }
.icon-lamp-desk:before { content: "\e2da"; }
.icon-lamp-floor:before { content: "\e2db"; }
.icon-lamp-wall-down:before { content: "\e2dc"; }
.icon-lamp-wall-up:before { content: "\e2dd"; }
.icon-lamp:before { content: "\e2d8"; }
.icon-land-plot:before { content: "\e52d"; }
.icon-landmark:before { content: "\e23a"; }
.icon-languages:before { content: "\e103"; }
.icon-laptop-minimal:before { content: "\e1d8"; }
.icon-laptop:before { content: "\e1cd"; }
.icon-lasso-select:before { content: "\e1cf"; }
.icon-lasso:before { content: "\e1ce"; }
.icon-laugh:before { content: "\e300"; }
.icon-layers-2:before { content: "\e52e"; }
.icon-layers-3:before { content: "\e52f"; }
.icon-layers:before { content: "\e104"; }
.icon-layout-dashboard:before { content: "\e1c1"; }
.icon-layout-grid:before { content: "\e105"; }
.icon-layout-list:before { content: "\e1d9"; }
.icon-layout-panel-left:before { content: "\e475"; }
.icon-layout-panel-top:before { content: "\e476"; }
.icon-layout-template:before { content: "\e207"; }
.icon-leaf:before { content: "\e2de"; }
.icon-leafy-green:before { content: "\e474"; }
.icon-lectern:before { content: "\e5ee"; }
.icon-letter-text:before { content: "\e60a"; }
.icon-library-big:before { content: "\e553"; }
.icon-library:before { content: "\e106"; }
.icon-life-buoy:before { content: "\e107"; }
.icon-ligature:before { content: "\e43f"; }
.icon-lightbulb-off:before { content: "\e208"; }
.icon-lightbulb:before { content: "\e1c2"; }
.icon-link-2-off:before { content: "\e10a"; }
.icon-link-2:before { content: "\e109"; }
.icon-link:before { content: "\e108"; }
.icon-linkedin:before { content: "\e10b"; }
.icon-list-check:before { content: "\e5ff"; }
.icon-list-checks:before { content: "\e1d0"; }
.icon-list-collapse:before { content: "\e5a0"; }
.icon-list-end:before { content: "\e2df"; }
.icon-list-filter:before { content: "\e465"; }
.icon-list-minus:before { content: "\e23e"; }
.icon-list-music:before { content: "\e2e0"; }
.icon-list-ordered:before { content: "\e1d1"; }
.icon-list-plus:before { content: "\e23f"; }
.icon-list-restart:before { content: "\e457"; }
.icon-list-start:before { content: "\e2e1"; }
.icon-list-todo:before { content: "\e4c8"; }
.icon-list-tree:before { content: "\e40d"; }
.icon-list-video:before { content: "\e2e2"; }
.icon-list-x:before { content: "\e240"; }
.icon-list:before { content: "\e10c"; }
.icon-loader-circle:before { content: "\e10e"; }
.icon-loader-pinwheel:before { content: "\e5eb"; }
.icon-loader:before { content: "\e10d"; }
.icon-locate-fixed:before { content: "\e1db"; }
.icon-locate-off:before { content: "\e282"; }
.icon-locate:before { content: "\e1da"; }
.icon-lock-keyhole-open:before { content: "\e537"; }
.icon-lock-keyhole:before { content: "\e536"; }
.icon-lock-open:before { content: "\e110"; }
.icon-lock:before { content: "\e10f"; }
.icon-log-in:before { content: "\e111"; }
.icon-log-out:before { content: "\e112"; }
.icon-logs:before { content: "\e5f9"; }
.icon-lollipop:before { content: "\e4c2"; }
.icon-luggage:before { content: "\e2ca"; }
.icon-magnet:before { content: "\e2b5"; }
.icon-mail-check:before { content: "\e365"; }
.icon-mail-minus:before { content: "\e366"; }
.icon-mail-open:before { content: "\e367"; }
.icon-mail-plus:before { content: "\e368"; }
.icon-mail-question:before { content: "\e369"; }
.icon-mail-search:before { content: "\e36a"; }
.icon-mail-warning:before { content: "\e36b"; }
.icon-mail-x:before { content: "\e36c"; }
.icon-mail:before { content: "\e113"; }
.icon-mailbox:before { content: "\e3d8"; }
.icon-mails:before { content: "\e36d"; }
.icon-map-pin-check-inside:before { content: "\e615"; }
.icon-map-pin-check:before { content: "\e614"; }
.icon-map-pin-house:before { content: "\e621"; }
.icon-map-pin-minus-inside:before { content: "\e617"; }
.icon-map-pin-minus:before { content: "\e616"; }
.icon-map-pin-off:before { content: "\e2a6"; }
.icon-map-pin-plus-inside:before { content: "\e619"; }
.icon-map-pin-plus:before { content: "\e618"; }
.icon-map-pin-x-inside:before { content: "\e61b"; }
.icon-map-pin-x:before { content: "\e61a"; }
.icon-map-pin:before { content: "\e115"; }
.icon-map-pinned:before { content: "\e542"; }
.icon-map:before { content: "\e114"; }
.icon-martini:before { content: "\e2e3"; }
.icon-maximize-2:before { content: "\e117"; }
.icon-maximize:before { content: "\e116"; }
.icon-medal:before { content: "\e373"; }
.icon-megaphone-off:before { content: "\e374"; }
.icon-megaphone:before { content: "\e235"; }
.icon-meh:before { content: "\e118"; }
.icon-memory-stick:before { content: "\e44a"; }
.icon-menu:before { content: "\e119"; }
.icon-merge:before { content: "\e444"; }
.icon-message-circle-code:before { content: "\e567"; }
.icon-message-circle-dashed:before { content: "\e568"; }
.icon-message-circle-heart:before { content: "\e569"; }
.icon-message-circle-more:before { content: "\e56a"; }
.icon-message-circle-off:before { content: "\e56b"; }
.icon-message-circle-plus:before { content: "\e56c"; }
.icon-message-circle-question:before { content: "\e56d"; }
.icon-message-circle-reply:before { content: "\e56e"; }
.icon-message-circle-warning:before { content: "\e56f"; }
.icon-message-circle-x:before { content: "\e570"; }
.icon-message-circle:before { content: "\e11a"; }
.icon-message-square-code:before { content: "\e571"; }
.icon-message-square-dashed:before { content: "\e410"; }
.icon-message-square-diff:before { content: "\e572"; }
.icon-message-square-dot:before { content: "\e573"; }
.icon-message-square-heart:before { content: "\e574"; }
.icon-message-square-lock:before { content: "\e631"; }
.icon-message-square-more:before { content: "\e575"; }
.icon-message-square-off:before { content: "\e576"; }
.icon-message-square-plus:before { content: "\e411"; }
.icon-message-square-quote:before { content: "\e577"; }
.icon-message-square-reply:before { content: "\e578"; }
.icon-message-square-share:before { content: "\e579"; }
.icon-message-square-text:before { content: "\e57a"; }
.icon-message-square-warning:before { content: "\e57b"; }
.icon-message-square-x:before { content: "\e57c"; }
.icon-message-square:before { content: "\e11b"; }
.icon-messages-square:before { content: "\e412"; }
.icon-mic-off:before { content: "\e11d"; }
.icon-mic-vocal:before { content: "\e34d"; }
.icon-mic:before { content: "\e11c"; }
.icon-microchip:before { content: "\e61f"; }
.icon-microscope:before { content: "\e2e4"; }
.icon-microwave:before { content: "\e37e"; }
.icon-milestone:before { content: "\e298"; }
.icon-milk-off:before { content: "\e39e"; }
.icon-milk:before { content: "\e39d"; }
.icon-minimize-2:before { content: "\e11f"; }
.icon-minimize:before { content: "\e11e"; }
.icon-minus:before { content: "\e120"; }
.icon-monitor-check:before { content: "\e487"; }
.icon-monitor-cog:before { content: "\e608"; }
.icon-monitor-dot:before { content: "\e488"; }
.icon-monitor-down:before { content: "\e426"; }
.icon-monitor-off:before { content: "\e1dc"; }
.icon-monitor-pause:before { content: "\e489"; }
.icon-monitor-play:before { content: "\e48a"; }
.icon-monitor-smartphone:before { content: "\e3a6"; }
.icon-monitor-speaker:before { content: "\e210"; }
.icon-monitor-stop:before { content: "\e48b"; }
.icon-monitor-up:before { content: "\e427"; }
.icon-monitor-x:before { content: "\e48c"; }
.icon-monitor:before { content: "\e121"; }
.icon-moon-star:before { content: "\e415"; }
.icon-moon:before { content: "\e122"; }
.icon-mountain-snow:before { content: "\e232"; }
.icon-mountain:before { content: "\e231"; }
.icon-mouse-off:before { content: "\e5e0"; }
.icon-mouse-pointer-2:before { content: "\e1c3"; }
.icon-mouse-pointer-ban:before { content: "\e5ec"; }
.icon-mouse-pointer-click:before { content: "\e124"; }
.icon-mouse-pointer:before { content: "\e123"; }
.icon-mouse:before { content: "\e28e"; }
.icon-move-3d:before { content: "\e2e5"; }
.icon-move-diagonal-2:before { content: "\e1c5"; }
.icon-move-diagonal:before { content: "\e1c4"; }
.icon-move-down-left:before { content: "\e492"; }
.icon-move-down-right:before { content: "\e493"; }
.icon-move-down:before { content: "\e491"; }
.icon-move-horizontal:before { content: "\e1c6"; }
.icon-move-left:before { content: "\e494"; }
.icon-move-right:before { content: "\e495"; }
.icon-move-up-left:before { content: "\e497"; }
.icon-move-up-right:before { content: "\e498"; }
.icon-move-up:before { content: "\e496"; }
.icon-move-vertical:before { content: "\e1c7"; }
.icon-move:before { content: "\e125"; }
.icon-music-2:before { content: "\e34e"; }
.icon-music-3:before { content: "\e34f"; }
.icon-music-4:before { content: "\e350"; }
.icon-music:before { content: "\e126"; }
.icon-navigation-2-off:before { content: "\e2a7"; }
.icon-navigation-2:before { content: "\e128"; }
.icon-navigation-off:before { content: "\e2a8"; }
.icon-navigation:before { content: "\e127"; }
.icon-network:before { content: "\e129"; }
.icon-newspaper:before { content: "\e34c"; }
.icon-nfc:before { content: "\e3c7"; }
.icon-notebook-pen:before { content: "\e59b"; }
.icon-notebook-tabs:before { content: "\e59c"; }
.icon-notebook-text:before { content: "\e59d"; }
.icon-notebook:before { content: "\e59a"; }
.icon-notepad-text-dashed:before { content: "\e59f"; }
.icon-notepad-text:before { content: "\e59e"; }
.icon-nut-off:before { content: "\e3a0"; }
.icon-nut:before { content: "\e39f"; }
.icon-octagon-alert:before { content: "\e12b"; }
.icon-octagon-minus:before { content: "\e62c"; }
.icon-octagon-pause:before { content: "\e21b"; }
.icon-octagon-x:before { content: "\e12c"; }
.icon-octagon:before { content: "\e12a"; }
.icon-omega:before { content: "\e61e"; }
.icon-option:before { content: "\e1f8"; }
.icon-orbit:before { content: "\e3eb"; }
.icon-origami:before { content: "\e5e8"; }
.icon-package-2:before { content: "\e344"; }
.icon-package-check:before { content: "\e266"; }
.icon-package-minus:before { content: "\e267"; }
.icon-package-open:before { content: "\e2cc"; }
.icon-package-plus:before { content: "\e268"; }
.icon-package-search:before { content: "\e269"; }
.icon-package-x:before { content: "\e26a"; }
.icon-package:before { content: "\e12d"; }
.icon-paint-bucket:before { content: "\e2e6"; }
.icon-paint-roller:before { content: "\e5a3"; }
.icon-paintbrush-vertical:before { content: "\e2e8"; }
.icon-paintbrush:before { content: "\e2e7"; }
.icon-palette:before { content: "\e1dd"; }
.icon-panel-bottom-close:before { content: "\e432"; }
.icon-panel-bottom-dashed:before { content: "\e433"; }
.icon-panel-bottom-open:before { content: "\e434"; }
.icon-panel-bottom:before { content: "\e431"; }
.icon-panel-left-close:before { content: "\e21c"; }
.icon-panel-left-dashed:before { content: "\e435"; }
.icon-panel-left-open:before { content: "\e21d"; }
.icon-panel-left:before { content: "\e12e"; }
.icon-panel-right-close:before { content: "\e437"; }
.icon-panel-right-dashed:before { content: "\e438"; }
.icon-panel-right-open:before { content: "\e439"; }
.icon-panel-right:before { content: "\e436"; }
.icon-panel-top-close:before { content: "\e43b"; }
.icon-panel-top-dashed:before { content: "\e43c"; }
.icon-panel-top-open:before { content: "\e43d"; }
.icon-panel-top:before { content: "\e43a"; }
.icon-panels-left-bottom:before { content: "\e12f"; }
.icon-panels-right-bottom:before { content: "\e58d"; }
.icon-panels-top-left:before { content: "\e130"; }
.icon-paperclip:before { content: "\e131"; }
.icon-parentheses:before { content: "\e449"; }
.icon-parking-meter:before { content: "\e505"; }
.icon-party-popper:before { content: "\e347"; }
.icon-pause:before { content: "\e132"; }
.icon-paw-print:before { content: "\e4fa"; }
.icon-pc-case:before { content: "\e44b"; }
.icon-pen-line:before { content: "\e134"; }
.icon-pen-off:before { content: "\e5f3"; }
.icon-pen-tool:before { content: "\e135"; }
.icon-pen:before { content: "\e133"; }
.icon-pencil-line:before { content: "\e4f5"; }
.icon-pencil-off:before { content: "\e5f4"; }
.icon-pencil-ruler:before { content: "\e4f6"; }
.icon-pencil:before { content: "\e1f9"; }
.icon-pentagon:before { content: "\e530"; }
.icon-percent:before { content: "\e136"; }
.icon-person-standing:before { content: "\e21e"; }
.icon-philippine-peso:before { content: "\e609"; }
.icon-phone-call:before { content: "\e138"; }
.icon-phone-forwarded:before { content: "\e139"; }
.icon-phone-incoming:before { content: "\e13a"; }
.icon-phone-missed:before { content: "\e13b"; }
.icon-phone-off:before { content: "\e13c"; }
.icon-phone-outgoing:before { content: "\e13d"; }
.icon-phone:before { content: "\e137"; }
.icon-pi:before { content: "\e477"; }
.icon-piano:before { content: "\e566"; }
.icon-pickaxe:before { content: "\e5cb"; }
.icon-picture-in-picture-2:before { content: "\e3b3"; }
.icon-picture-in-picture:before { content: "\e3b2"; }
.icon-piggy-bank:before { content: "\e13e"; }
.icon-pilcrow-left:before { content: "\e5e1"; }
.icon-pilcrow-right:before { content: "\e5e2"; }
.icon-pilcrow:before { content: "\e3a7"; }
.icon-pill-bottle:before { content: "\e5ef"; }
.icon-pill:before { content: "\e3c1"; }
.icon-pin-off:before { content: "\e2b6"; }
.icon-pin:before { content: "\e259"; }
.icon-pipette:before { content: "\e13f"; }
.icon-pizza:before { content: "\e358"; }
.icon-plane-landing:before { content: "\e3d1"; }
.icon-plane-takeoff:before { content: "\e3d2"; }
.icon-plane:before { content: "\e1de"; }
.icon-play:before { content: "\e140"; }
.icon-plug-2:before { content: "\e384"; }
.icon-plug-zap:before { content: "\e461"; }
.icon-plug:before { content: "\e383"; }
.icon-plus:before { content: "\e141"; }
.icon-pocket-knife:before { content: "\e4a5"; }
.icon-pocket:before { content: "\e142"; }
.icon-podcast:before { content: "\e1fa"; }
.icon-pointer-off:before { content: "\e584"; }
.icon-pointer:before { content: "\e1e8"; }
.icon-popcorn:before { content: "\e4c3"; }
.icon-popsicle:before { content: "\e4c4"; }
.icon-pound-sterling:before { content: "\e143"; }
.icon-power-off:before { content: "\e209"; }
.icon-power:before { content: "\e144"; }
.icon-presentation:before { content: "\e4b3"; }
.icon-printer-check:before { content: "\e5fa"; }
.icon-printer:before { content: "\e145"; }
.icon-projector:before { content: "\e4b4"; }
.icon-proportions:before { content: "\e5d4"; }
.icon-puzzle:before { content: "\e29c"; }
.icon-pyramid:before { content: "\e531"; }
.icon-qr-code:before { content: "\e1df"; }
.icon-quote:before { content: "\e239"; }
.icon-rabbit:before { content: "\e4fb"; }
.icon-radar:before { content: "\e49c"; }
.icon-radiation:before { content: "\e447"; }
.icon-radical:before { content: "\e5c7"; }
.icon-radio-receiver:before { content: "\e1fb"; }
.icon-radio-tower:before { content: "\e409"; }
.icon-radio:before { content: "\e146"; }
.icon-radius:before { content: "\e532"; }
.icon-rail-symbol:before { content: "\e506"; }
.icon-rainbow:before { content: "\e4c7"; }
.icon-rat:before { content: "\e3f0"; }
.icon-ratio:before { content: "\e4ed"; }
.icon-receipt-cent:before { content: "\e5aa"; }
.icon-receipt-euro:before { content: "\e5ab"; }
.icon-receipt-indian-rupee:before { content: "\e5ac"; }
.icon-receipt-japanese-yen:before { content: "\e5ad"; }
.icon-receipt-pound-sterling:before { content: "\e5ae"; }
.icon-receipt-russian-ruble:before { content: "\e5af"; }
.icon-receipt-swiss-franc:before { content: "\e5b0"; }
.icon-receipt-text:before { content: "\e5b1"; }
.icon-receipt:before { content: "\e3d7"; }
.icon-rectangle-ellipsis:before { content: "\e21f"; }
.icon-rectangle-horizontal:before { content: "\e37a"; }
.icon-rectangle-vertical:before { content: "\e37b"; }
.icon-recycle:before { content: "\e2e9"; }
.icon-redo-2:before { content: "\e2a0"; }
.icon-redo-dot:before { content: "\e455"; }
.icon-redo:before { content: "\e147"; }
.icon-refresh-ccw-dot:before { content: "\e4b7"; }
.icon-refresh-ccw:before { content: "\e148"; }
.icon-refresh-cw-off:before { content: "\e49d"; }
.icon-refresh-cw:before { content: "\e149"; }
.icon-refrigerator:before { content: "\e37f"; }
.icon-regex:before { content: "\e1fc"; }
.icon-remove-formatting:before { content: "\e3b7"; }
.icon-repeat-1:before { content: "\e1fd"; }
.icon-repeat-2:before { content: "\e416"; }
.icon-repeat:before { content: "\e14a"; }
.icon-replace-all:before { content: "\e3e0"; }
.icon-replace:before { content: "\e3df"; }
.icon-reply-all:before { content: "\e22b"; }
.icon-reply:before { content: "\e22a"; }
.icon-rewind:before { content: "\e14b"; }
.icon-ribbon:before { content: "\e55d"; }
.icon-rocket:before { content: "\e286"; }
.icon-rocking-chair:before { content: "\e233"; }
.icon-roller-coaster:before { content: "\e485"; }
.icon-rotate-3d:before { content: "\e2ea"; }
.icon-rotate-ccw-square:before { content: "\e5d5"; }
.icon-rotate-ccw:before { content: "\e14c"; }
.icon-rotate-cw-square:before { content: "\e5d6"; }
.icon-rotate-cw:before { content: "\e14d"; }
.icon-route-off:before { content: "\e544"; }
.icon-route:before { content: "\e543"; }
.icon-router:before { content: "\e3c3"; }
.icon-rows-2:before { content: "\e43e"; }
.icon-rows-3:before { content: "\e58f"; }
.icon-rows-4:before { content: "\e590"; }
.icon-rss:before { content: "\e14e"; }
.icon-ruler:before { content: "\e14f"; }
.icon-russian-ruble:before { content: "\e150"; }
.icon-sailboat:before { content: "\e382"; }
.icon-salad:before { content: "\e3ac"; }
.icon-sandwich:before { content: "\e3ad"; }
.icon-satellite-dish:before { content: "\e44d"; }
.icon-satellite:before { content: "\e44c"; }
.icon-save-all:before { content: "\e414"; }
.icon-save-off:before { content: "\e5f8"; }
.icon-save:before { content: "\e151"; }
.icon-scale-3d:before { content: "\e2eb"; }
.icon-scale:before { content: "\e212"; }
.icon-scaling:before { content: "\e2ec"; }
.icon-scan-barcode:before { content: "\e53a"; }
.icon-scan-eye:before { content: "\e53b"; }
.icon-scan-face:before { content: "\e375"; }
.icon-scan-line:before { content: "\e258"; }
.icon-scan-qr-code:before { content: "\e5fb"; }
.icon-scan-search:before { content: "\e53c"; }
.icon-scan-text:before { content: "\e53d"; }
.icon-scan:before { content: "\e257"; }
.icon-school:before { content: "\e3e7"; }
.icon-scissors-line-dashed:before { content: "\e4ee"; }
.icon-scissors:before { content: "\e152"; }
.icon-screen-share-off:before { content: "\e154"; }
.icon-screen-share:before { content: "\e153"; }
.icon-scroll-text:before { content: "\e464"; }
.icon-scroll:before { content: "\e2ed"; }
.icon-search-check:before { content: "\e4af"; }
.icon-search-code:before { content: "\e4b0"; }
.icon-search-slash:before { content: "\e4b1"; }
.icon-search-x:before { content: "\e4b2"; }
.icon-search:before { content: "\e155"; }
.icon-section:before { content: "\e5ed"; }
.icon-send-horizontal:before { content: "\e4f7"; }
.icon-send-to-back:before { content: "\e4f8"; }
.icon-send:before { content: "\e156"; }
.icon-separator-horizontal:before { content: "\e1c8"; }
.icon-separator-vertical:before { content: "\e1c9"; }
.icon-server-cog:before { content: "\e345"; }
.icon-server-crash:before { content: "\e1e9"; }
.icon-server-off:before { content: "\e1ea"; }
.icon-server:before { content: "\e157"; }
.icon-settings-2:before { content: "\e245"; }
.icon-settings:before { content: "\e158"; }
.icon-shapes:before { content: "\e4b8"; }
.icon-share-2:before { content: "\e15a"; }
.icon-share:before { content: "\e159"; }
.icon-sheet:before { content: "\e15b"; }
.icon-shell:before { content: "\e4fc"; }
.icon-shield-alert:before { content: "\e1fe"; }
.icon-shield-ban:before { content: "\e15d"; }
.icon-shield-check:before { content: "\e1ff"; }
.icon-shield-ellipsis:before { content: "\e51b"; }
.icon-shield-half:before { content: "\e51c"; }
.icon-shield-minus:before { content: "\e51d"; }
.icon-shield-off:before { content: "\e15e"; }
.icon-shield-plus:before { content: "\e51e"; }
.icon-shield-question:before { content: "\e413"; }
.icon-shield-x:before { content: "\e200"; }
.icon-shield:before { content: "\e15c"; }
.icon-ship-wheel:before { content: "\e507"; }
.icon-ship:before { content: "\e3be"; }
.icon-shirt:before { content: "\e1ca"; }
.icon-shopping-bag:before { content: "\e15f"; }
.icon-shopping-basket:before { content: "\e4ef"; }
.icon-shopping-cart:before { content: "\e160"; }
.icon-shovel:before { content: "\e161"; }
.icon-shower-head:before { content: "\e380"; }
.icon-shrink:before { content: "\e220"; }
.icon-shrub:before { content: "\e2ee"; }
.icon-shuffle:before { content: "\e162"; }
.icon-sigma:before { content: "\e201"; }
.icon-signal-high:before { content: "\e260"; }
.icon-signal-low:before { content: "\e261"; }
.icon-signal-medium:before { content: "\e262"; }
.icon-signal-zero:before { content: "\e263"; }
.icon-signal:before { content: "\e25f"; }
.icon-signature:before { content: "\e5f7"; }
.icon-signpost-big:before { content: "\e546"; }
.icon-signpost:before { content: "\e545"; }
.icon-siren:before { content: "\e2ef"; }
.icon-skip-back:before { content: "\e163"; }
.icon-skip-forward:before { content: "\e164"; }
.icon-skull:before { content: "\e221"; }
.icon-slack:before { content: "\e165"; }
.icon-slash:before { content: "\e522"; }
.icon-slice:before { content: "\e2f0"; }
.icon-sliders-horizontal:before { content: "\e29a"; }
.icon-sliders-vertical:before { content: "\e166"; }
.icon-smartphone-charging:before { content: "\e22e"; }
.icon-smartphone-nfc:before { content: "\e3c8"; }
.icon-smartphone:before { content: "\e167"; }
.icon-smile-plus:before { content: "\e301"; }
.icon-smile:before { content: "\e168"; }
.icon-snail:before { content: "\e4fd"; }
.icon-snowflake:before { content: "\e169"; }
.icon-sofa:before { content: "\e2c4"; }
.icon-soup:before { content: "\e3ae"; }
.icon-space:before { content: "\e3e1"; }
.icon-spade:before { content: "\e49e"; }
.icon-sparkle:before { content: "\e483"; }
.icon-sparkles:before { content: "\e417"; }
.icon-speaker:before { content: "\e16a"; }
.icon-speech:before { content: "\e523"; }
.icon-spell-check-2:before { content: "\e4a0"; }
.icon-spell-check:before { content: "\e49f"; }
.icon-spline:before { content: "\e38f"; }
.icon-split:before { content: "\e445"; }
.icon-spray-can:before { content: "\e49a"; }
.icon-sprout:before { content: "\e1eb"; }
.icon-square-activity:before { content: "\e4b9"; }
.icon-square-arrow-down-left:before { content: "\e4ba"; }
.icon-square-arrow-down-right:before { content: "\e4bb"; }
.icon-square-arrow-down:before { content: "\e42c"; }
.icon-square-arrow-left:before { content: "\e42d"; }
.icon-square-arrow-out-down-left:before { content: "\e5a6"; }
.icon-square-arrow-out-down-right:before { content: "\e5a7"; }
.icon-square-arrow-out-up-left:before { content: "\e5a8"; }
.icon-square-arrow-out-up-right:before { content: "\e5a9"; }
.icon-square-arrow-right:before { content: "\e42e"; }
.icon-square-arrow-up-left:before { content: "\e4bc"; }
.icon-square-arrow-up-right:before { content: "\e4bd"; }
.icon-square-arrow-up:before { content: "\e42f"; }
.icon-square-asterisk:before { content: "\e16c"; }
.icon-square-bottom-dashed-scissors:before { content: "\e4f0"; }
.icon-square-chart-gantt:before { content: "\e16d"; }
.icon-square-check-big:before { content: "\e16e"; }
.icon-square-check:before { content: "\e55e"; }
.icon-square-chevron-down:before { content: "\e3d3"; }
.icon-square-chevron-left:before { content: "\e3d4"; }
.icon-square-chevron-right:before { content: "\e3d5"; }
.icon-square-chevron-up:before { content: "\e3d6"; }
.icon-square-code:before { content: "\e16f"; }
.icon-square-dashed-bottom-code:before { content: "\e4c6"; }
.icon-square-dashed-bottom:before { content: "\e4c5"; }
.icon-square-dashed-kanban:before { content: "\e170"; }
.icon-square-dashed-mouse-pointer:before { content: "\e50e"; }
.icon-square-dashed:before { content: "\e1cb"; }
.icon-square-divide:before { content: "\e171"; }
.icon-square-dot:before { content: "\e172"; }
.icon-square-equal:before { content: "\e173"; }
.icon-square-function:before { content: "\e22d"; }
.icon-square-kanban:before { content: "\e174"; }
.icon-square-library:before { content: "\e554"; }
.icon-square-m:before { content: "\e508"; }
.icon-square-menu:before { content: "\e458"; }
.icon-square-minus:before { content: "\e175"; }
.icon-square-mouse-pointer:before { content: "\e202"; }
.icon-square-parking-off:before { content: "\e3d0"; }
.icon-square-parking:before { content: "\e3cf"; }
.icon-square-pen:before { content: "\e176"; }
.icon-square-percent:before { content: "\e521"; }
.icon-square-pi:before { content: "\e48d"; }
.icon-square-pilcrow:before { content: "\e490"; }
.icon-square-play:before { content: "\e486"; }
.icon-square-plus:before { content: "\e177"; }
.icon-square-power:before { content: "\e556"; }
.icon-square-radical:before { content: "\e5c8"; }
.icon-square-scissors:before { content: "\e4f1"; }
.icon-square-sigma:before { content: "\e48e"; }
.icon-square-slash:before { content: "\e178"; }
.icon-square-split-horizontal:before { content: "\e3ba"; }
.icon-square-split-vertical:before { content: "\e3bb"; }
.icon-square-square:before { content: "\e613"; }
.icon-square-stack:before { content: "\e4a7"; }
.icon-square-terminal:before { content: "\e20a"; }
.icon-square-user-round:before { content: "\e46b"; }
.icon-square-user:before { content: "\e46a"; }
.icon-square-x:before { content: "\e179"; }
.icon-square:before { content: "\e16b"; }
.icon-squircle:before { content: "\e57f"; }
.icon-squirrel:before { content: "\e4a4"; }
.icon-stamp:before { content: "\e3bf"; }
.icon-star-half:before { content: "\e20b"; }
.icon-star-off:before { content: "\e2b0"; }
.icon-star:before { content: "\e17a"; }
.icon-step-back:before { content: "\e3ed"; }
.icon-step-forward:before { content: "\e3ee"; }
.icon-stethoscope:before { content: "\e2f1"; }
.icon-sticker:before { content: "\e302"; }
.icon-sticky-note:before { content: "\e303"; }
.icon-store:before { content: "\e3e8"; }
.icon-stretch-horizontal:before { content: "\e27c"; }
.icon-stretch-vertical:before { content: "\e27d"; }
.icon-strikethrough:before { content: "\e17b"; }
.icon-subscript:before { content: "\e25c"; }
.icon-sun-dim:before { content: "\e299"; }
.icon-sun-medium:before { content: "\e2b1"; }
.icon-sun-moon:before { content: "\e2b2"; }
.icon-sun-snow:before { content: "\e376"; }
.icon-sun:before { content: "\e17c"; }
.icon-sunrise:before { content: "\e17d"; }
.icon-sunset:before { content: "\e17e"; }
.icon-superscript:before { content: "\e25e"; }
.icon-swatch-book:before { content: "\e5a4"; }
.icon-swiss-franc:before { content: "\e17f"; }
.icon-switch-camera:before { content: "\e180"; }
.icon-sword:before { content: "\e2b3"; }
.icon-swords:before { content: "\e2b4"; }
.icon-syringe:before { content: "\e2f2"; }
.icon-table-2:before { content: "\e2f9"; }
.icon-table-cells-merge:before { content: "\e5cc"; }
.icon-table-cells-split:before { content: "\e5cd"; }
.icon-table-columns-split:before { content: "\e5ce"; }
.icon-table-of-contents:before { content: "\e623"; }
.icon-table-properties:before { content: "\e4e0"; }
.icon-table-rows-split:before { content: "\e5cf"; }
.icon-table:before { content: "\e181"; }
.icon-tablet-smartphone:before { content: "\e50f"; }
.icon-tablet:before { content: "\e182"; }
.icon-tablets:before { content: "\e3c2"; }
.icon-tag:before { content: "\e183"; }
.icon-tags:before { content: "\e360"; }
.icon-tally-1:before { content: "\e4db"; }
.icon-tally-2:before { content: "\e4dc"; }
.icon-tally-3:before { content: "\e4dd"; }
.icon-tally-4:before { content: "\e4de"; }
.icon-tally-5:before { content: "\e4df"; }
.icon-tangent:before { content: "\e533"; }
.icon-target:before { content: "\e184"; }
.icon-telescope:before { content: "\e5ca"; }
.icon-tent-tree:before { content: "\e540"; }
.icon-tent:before { content: "\e227"; }
.icon-terminal:before { content: "\e185"; }
.icon-test-tube-diagonal:before { content: "\e40b"; }
.icon-test-tube:before { content: "\e40a"; }
.icon-test-tubes:before { content: "\e40c"; }
.icon-text-cursor-input:before { content: "\e265"; }
.icon-text-cursor:before { content: "\e264"; }
.icon-text-quote:before { content: "\e4a3"; }
.icon-text-search:before { content: "\e5b2"; }
.icon-text-select:before { content: "\e3e2"; }
.icon-text:before { content: "\e3ef"; }
.icon-theater:before { content: "\e527"; }
.icon-thermometer-snowflake:before { content: "\e187"; }
.icon-thermometer-sun:before { content: "\e188"; }
.icon-thermometer:before { content: "\e186"; }
.icon-thumbs-down:before { content: "\e189"; }
.icon-thumbs-up:before { content: "\e18a"; }
.icon-ticket-check:before { content: "\e5b3"; }
.icon-ticket-minus:before { content: "\e5b4"; }
.icon-ticket-percent:before { content: "\e5b5"; }
.icon-ticket-plus:before { content: "\e5b6"; }
.icon-ticket-slash:before { content: "\e5b7"; }
.icon-ticket-x:before { content: "\e5b8"; }
.icon-ticket:before { content: "\e20f"; }
.icon-tickets-plane:before { content: "\e628"; }
.icon-tickets:before { content: "\e627"; }
.icon-timer-off:before { content: "\e249"; }
.icon-timer-reset:before { content: "\e236"; }
.icon-timer:before { content: "\e1e0"; }
.icon-toggle-left:before { content: "\e18b"; }
.icon-toggle-right:before { content: "\e18c"; }
.icon-tornado:before { content: "\e218"; }
.icon-torus:before { content: "\e534"; }
.icon-touchpad-off:before { content: "\e44f"; }
.icon-touchpad:before { content: "\e44e"; }
.icon-tower-control:before { content: "\e3c0"; }
.icon-toy-brick:before { content: "\e34b"; }
.icon-tractor:before { content: "\e509"; }
.icon-traffic-cone:before { content: "\e50a"; }
.icon-train-front-tunnel:before { content: "\e50c"; }
.icon-train-front:before { content: "\e50b"; }
.icon-train-track:before { content: "\e50d"; }
.icon-tram-front:before { content: "\e2a9"; }
.icon-trash-2:before { content: "\e18e"; }
.icon-trash:before { content: "\e18d"; }
.icon-tree-deciduous:before { content: "\e2f3"; }
.icon-tree-palm:before { content: "\e281"; }
.icon-tree-pine:before { content: "\e2f4"; }
.icon-trees:before { content: "\e2f5"; }
.icon-trello:before { content: "\e18f"; }
.icon-trending-down:before { content: "\e190"; }
.icon-trending-up-down:before { content: "\e62a"; }
.icon-trending-up:before { content: "\e191"; }
.icon-triangle-alert:before { content: "\e193"; }
.icon-triangle-right:before { content: "\e4f2"; }
.icon-triangle:before { content: "\e192"; }
.icon-trophy:before { content: "\e377"; }
.icon-truck:before { content: "\e194"; }
.icon-turtle:before { content: "\e4fe"; }
.icon-tv-minimal-play:before { content: "\e5f1"; }
.icon-tv-minimal:before { content: "\e203"; }
.icon-tv:before { content: "\e195"; }
.icon-twitch:before { content: "\e196"; }
.icon-twitter:before { content: "\e197"; }
.icon-type-outline:before { content: "\e607"; }
.icon-type:before { content: "\e198"; }
.icon-umbrella-off:before { content: "\e548"; }
.icon-umbrella:before { content: "\e199"; }
.icon-underline:before { content: "\e19a"; }
.icon-undo-2:before { content: "\e2a1"; }
.icon-undo-dot:before { content: "\e456"; }
.icon-undo:before { content: "\e19b"; }
.icon-unfold-horizontal:before { content: "\e442"; }
.icon-unfold-vertical:before { content: "\e443"; }
.icon-ungroup:before { content: "\e46c"; }
.icon-university:before { content: "\e3e9"; }
.icon-unlink-2:before { content: "\e19d"; }
.icon-unlink:before { content: "\e19c"; }
.icon-unplug:before { content: "\e462"; }
.icon-upload:before { content: "\e19e"; }
.icon-usb:before { content: "\e35a"; }
.icon-user-check:before { content: "\e1a0"; }
.icon-user-cog:before { content: "\e346"; }
.icon-user-minus:before { content: "\e1a1"; }
.icon-user-pen:before { content: "\e601"; }
.icon-user-plus:before { content: "\e1a2"; }
.icon-user-round-check:before { content: "\e46e"; }
.icon-user-round-cog:before { content: "\e46f"; }
.icon-user-round-minus:before { content: "\e470"; }
.icon-user-round-pen:before { content: "\e602"; }
.icon-user-round-plus:before { content: "\e471"; }
.icon-user-round-search:before { content: "\e57d"; }
.icon-user-round-x:before { content: "\e472"; }
.icon-user-round:before { content: "\e46d"; }
.icon-user-search:before { content: "\e57e"; }
.icon-user-x:before { content: "\e1a3"; }
.icon-user:before { content: "\e19f"; }
.icon-users-round:before { content: "\e473"; }
.icon-users:before { content: "\e1a4"; }
.icon-utensils-crossed:before { content: "\e2f7"; }
.icon-utensils:before { content: "\e2f6"; }
.icon-utility-pole:before { content: "\e3c6"; }
.icon-variable:before { content: "\e478"; }
.icon-vault:before { content: "\e594"; }
.icon-vegan:before { content: "\e3a1"; }
.icon-venetian-mask:before { content: "\e2aa"; }
.icon-vibrate-off:before { content: "\e29d"; }
.icon-vibrate:before { content: "\e223"; }
.icon-video-off:before { content: "\e1a6"; }
.icon-video:before { content: "\e1a5"; }
.icon-videotape:before { content: "\e4d0"; }
.icon-view:before { content: "\e1a7"; }
.icon-voicemail:before { content: "\e1a8"; }
.icon-volleyball:before { content: "\e634"; }
.icon-volume-1:before { content: "\e1aa"; }
.icon-volume-2:before { content: "\e1ab"; }
.icon-volume-off:before { content: "\e62b"; }
.icon-volume-x:before { content: "\e1ac"; }
.icon-volume:before { content: "\e1a9"; }
.icon-vote:before { content: "\e3b1"; }
.icon-wallet-cards:before { content: "\e4d1"; }
.icon-wallet-minimal:before { content: "\e4d2"; }
.icon-wallet:before { content: "\e204"; }
.icon-wallpaper:before { content: "\e450"; }
.icon-wand-sparkles:before { content: "\e35b"; }
.icon-wand:before { content: "\e246"; }
.icon-warehouse:before { content: "\e3ea"; }
.icon-washing-machine:before { content: "\e595"; }
.icon-watch:before { content: "\e1ad"; }
.icon-waves:before { content: "\e283"; }
.icon-waypoints:before { content: "\e547"; }
.icon-webcam:before { content: "\e205"; }
.icon-webhook-off:before { content: "\e5bc"; }
.icon-webhook:before { content: "\e378"; }
.icon-weight:before { content: "\e535"; }
.icon-wheat-off:before { content: "\e3a3"; }
.icon-wheat:before { content: "\e3a2"; }
.icon-whole-word:before { content: "\e3e3"; }
.icon-wifi-high:before { content: "\e5fc"; }
.icon-wifi-low:before { content: "\e5fd"; }
.icon-wifi-off:before { content: "\e1af"; }
.icon-wifi-zero:before { content: "\e5fe"; }
.icon-wifi:before { content: "\e1ae"; }
.icon-wind:before { content: "\e1b0"; }
.icon-wine-off:before { content: "\e3a4"; }
.icon-wine:before { content: "\e2f8"; }
.icon-workflow:before { content: "\e42a"; }
.icon-worm:before { content: "\e5df"; }
.icon-wrap-text:before { content: "\e248"; }
.icon-wrench:before { content: "\e1b1"; }
.icon-x:before { content: "\e1b2"; }
.icon-youtube:before { content: "\e1b3"; }
.icon-zap-off:before { content: "\e1b5"; }
.icon-zap:before { content: "\e1b4"; }
.icon-zoom-in:before { content: "\e1b6"; }
.icon-zoom-out:before { content: "\e1b7"; }

